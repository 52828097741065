type MidenLogoProps = {
  className: string;
};

/**
 * A component that renders the Miden logo.
 *
 * @returns an svg component of the Polygon Miden logo
 */
const MidenLogo = ({ className }: MidenLogoProps) => {
  return (
    <svg className={className} version="1.0" viewBox="0 0 400 400">
      <path d="M187.8 42.5c-4.7 1.3-7.7 2.9-64.8 35.9-50.9 29.5-50.4 29.2-55.7 35-5.5 5.9-7.9 10.4-9.9 18.1-2 7.4-2 129.7 0 137.1 3 11.2 11.2 21.6 21.8 27.6 4.5 2.5 34.4 19.8 74.3 42.9 32.4 18.7 35.1 19.9 45.4 19.9 8.9 0 16.6-2.7 30-10.4 6.9-4 20.3-11.7 29.6-17.1 9.4-5.3 21.7-12.5 27.5-16 5.8-3.4 16.8-9.7 24.4-14 7.6-4.4 16.2-10.1 19.1-12.7 5.9-5.4 10.8-13.9 12.4-21.6.7-3.6 1.1-25.8 1.1-67.5 0-67.2-.1-68-5.4-78.5-4.2-8.2-10.7-13.2-36.1-28-13.2-7.6-26-15-28.5-16.4-2.5-1.4-11.2-6.5-19.5-11.3-40.6-23.6-42.8-24.6-54.4-24.4-3.6 0-8.7.7-11.3 1.4zm19.6 18.6c1.6.5 6.5 3.1 11 5.7 4.4 2.7 14.7 8.6 22.8 13.3 8.2 4.6 14.8 8.7 14.8 9 0 .4-3.1 2.5-6.9 4.8-8.5 5-16.2 13.4-20.3 22.1-1.7 3.6-3.2 6.6-3.3 6.8-.1.1-2.5-.6-5.3-1.7s-9.2-2.6-14.2-3.2c-23.4-3-46.8 7.3-60.5 26.5-11 15.6-12.4 21.9-12.5 54-.1 15.2.4 27.3 1 30.6 1.5 7.2 6.9 19 8.7 19 .8 0 3.3-4.3 5.9-9.7 4.3-9.4 4.4-9.8 3.4-15.2-1.3-7.3-1.3-41.2.1-48.7 4.7-25.9 30.6-42.9 56.7-37.3 9.5 2 9.6.8-2.2 25.3-5.8 12-10.6 22-10.6 22.1 0 .2-3.4 7.4-7.6 16.1-4.2 8.8-9.1 19-10.9 22.9-1.8 3.8-6.2 13.1-9.8 20.5-3.6 7.4-8.9 18.7-11.9 25-7.9 16.5-14.4 22.5-27.8 25-10 1.9-17.7-.8-38.7-13.7-7.1-4.4-10.7-8.1-13.1-13.6-1.6-3.6-1.7-129.1-.2-133.3 2.7-7 7.2-10.6 26.5-21.4 2.2-1.2 4.5-2.6 5-3 .6-.4 13.2-7.7 28-16.3 14.9-8.5 33.5-19.2 41.4-23.8 17.3-9.9 20.9-10.8 30.5-7.8zm82.6 47.4c9.3 4.7 24.4 13.7 27.2 16.3 1.5 1.4 3.6 4.5 4.8 6.8 1.9 4.3 2 6 2 67.5 0 45.7-.3 64.3-1.2 67.1-2.2 7.3-6.7 10.6-42.8 31.3-19 10.9-43 24.8-53.5 30.9l-19 11.1h-16l-25.4-14.7-25.3-14.7 6.3-3.2c8.8-4.4 17.7-13.6 22.5-23.3 2-4.2 4-7.6 4.3-7.6.3 0 3.5 1 7.1 2.1 8.5 2.7 23.8 2.9 33 .5 12.6-3.4 24.4-10.7 33.4-20.7 6.3-7 13.3-21.4 15.1-31.2 2.1-11.1 2.1-46.1-.1-56.5-1.7-8.4-5.7-18.2-7.3-18.2-.6 0-3.2 4.4-5.7 9.7l-4.6 9.8.1 25c.2 27-.3 30.4-5.6 40.9-3.3 6.5-12.5 15.7-19.1 19-6.5 3.3-15.2 5.6-21.2 5.6-6.3 0-16.1-2-16.6-3.4-.3-.7 2.9-8.1 7-16.6 11.2-23.6 27.3-57.3 32.6-68.5 2.6-5.5 8-16.8 12-25 4-8.3 8.3-17.5 9.7-20.5 4.9-11.1 11.6-17.9 21.3-21.3 7-2.5 18.1-1.7 25 1.8z" />
    </svg>
  );
};

export default MidenLogo;
